<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <div class="global-header-selects">

      <b-form class="d-flex">
        <b-form-group style="width: 250px">
          <v-select
            id="principal"
            v-model="selectedPrincipal"
            :options="principals"
            width="100%"
            label="name"
            :placeholder="$t('Select') + ' ' + $t('Principal')"
            @input="onPrincipalChange"
          />
        </b-form-group>
        <b-form-group
          style="width: 250px; margin-left: 5px; margin-right: 20px"
        >
          <v-select
            id="parkingLot"
            v-model="selectedParkingLot"
            :options="parkingLots"
            width="100%"
            label="name"
            :disabled="selectedPrincipal == null"
            :placeholder="$t('Select') + ' ' + $t('Parking Lot')"
          />
        </b-form-group>
      </b-form>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
    <locale />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0" id="navbar-username">{{ account.name }}</p>
            <!-- <span class="user-status">Admin</span> -->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/no-avatar.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="signOut"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{$t('Logout')}}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BButton,
  BModal,
  BForm,
  VBModal,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BImg,
  BRow,
  BCol,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import {} from "bootstrap-vue";
import vSelect from "vue-select";
import Locale from "./Locale.vue"

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BButton,
    BModal,
    BForm,
    VBModal,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BImg,
    BRow,
    BCol,
    vSelect,

    // Navbar Components
    DarkToggler,
    Locale
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      // selectedParkingLot: null,
      // selectedPrincipal: null,
    };
  },
  computed: {
    account() {
      return this.$store.state.auth.account;
    },
    principals() {
      return this.$store.state.principal.principals;
    },
    parkingLots() {
      if (this.selectedPrincipal) {
        return this.$store.state.parkingLot.parkingLots.filter(
          (pl) => pl.principal?.id == this.selectedPrincipal?.id
        );
      }
      return this.$store.state.parkingLot.parkingLots;
    },
    selectedPrincipal: {
      get() {
        return this.$store.state.global.selectedPrincipal;
      },
      set(val) {
        this.$store.commit("global/SET_GLOBAL_PRINCIPAL", val);
      },
    },
    selectedParkingLot: {
      get() {
        return this.$store.state.global.selectedParkingLot;
      },
      set(val) {
        this.$store.commit("global/SET_GLOBAL_PARKINGLOT", val);
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("parkingLot/get", this.$bvToast);
    await this.$store.dispatch("principal/get", this.$bvToast);
  },
  methods: {
    onPrincipalChange(principal) {
      this.selectedParkingLot = null;
    },
    async signOut() {
      localStorage.removeItem("user_uptime");
      await this.$msalInstance
        .logout({})
        .then(() => {
          this.$emitter.emit("logout", "logging out");
        })
        .catch((error) => {
          console.error(error);
        });
      return;
      this.$store.dispatch("auth/signOut", this.$msalInstance, this.$emitter);
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";

.global-header-selects {
  .form-group {
    margin-bottom: 0 !important;
  }
  .v-select.vs--single .vs__selected {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 175px;
    white-space: nowrap;
  }
}
</style>
