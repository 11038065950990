export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Parking Lots',
    route: 'parkinglots',
    icon: 'DropletIcon',
  },
  {
    title: 'Scenes',
    route: 'scenes',
    icon: 'DropletIcon',
  },
  {
    title: 'Spaces',
    route: 'spaces',
    icon: 'DropletIcon',
  },
  {
    title: 'Devices',
    route: 'devices',
    icon: 'DropletIcon',
  },
]
